/* noto-sans-400normal - latin */
@font-face {
  font-family: 'Noto Sans';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src:
    local('Noto Sans Regular '),
    local('Noto Sans-Regular'),
    url('./files/noto-sans-latin-400.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/noto-sans-latin-400.woff') format('woff'); /* Modern Browsers */
}

/* noto-sans-400italic - latin */
@font-face {
  font-family: 'Noto Sans';
  font-style: italic;
  font-display: swap;
  font-weight: 400;
  src:
    local('Noto Sans Regular italic'),
    local('Noto Sans-Regularitalic'),
    url('./files/noto-sans-latin-400italic.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/noto-sans-latin-400italic.woff') format('woff'); /* Modern Browsers */
}

/* noto-sans-700normal - latin */
@font-face {
  font-family: 'Noto Sans';
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src:
    local('Noto Sans Bold '),
    local('Noto Sans-Bold'),
    url('./files/noto-sans-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/noto-sans-latin-700.woff') format('woff'); /* Modern Browsers */
}

/* noto-sans-700italic - latin */
@font-face {
  font-family: 'Noto Sans';
  font-style: italic;
  font-display: swap;
  font-weight: 700;
  src:
    local('Noto Sans Bold italic'),
    local('Noto Sans-Bolditalic'),
    url('./files/noto-sans-latin-700italic.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/noto-sans-latin-700italic.woff') format('woff'); /* Modern Browsers */
}

